<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      {{ item }}
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCodeEtc',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
